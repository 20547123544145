import styled from "styled-components";

export const SectionContainer = ({ children, background, center }) => {
  return (
    <Container background={background} center={center}>
      <InternalContainer>{children}</InternalContainer>
    </Container>
  );
};

const Container = styled.div`
  min-height: 90vh;
  padding: 4rem 4.1rem;
  background-color: ${(props) => props.background};
  justify-content: ${(props) => (props.center ? "center" : "auto")};
  display: ${(props) => (props.center ? "flex" : "auto")};

  @media screen and (max-width: 468px) {
    padding: 0vw;
  }

  @media screen and (max-height: 468px) {
    padding: 0vw;
  }
`;

const InternalContainer = styled.div`
  padding: 4rem 4.1rem;

  @media screen and (max-width: 468px) {
    padding: 5vw;
  }
`;
