import React from "react";
import styled from "styled-components";
// import background from "../assets/background.jpeg";
// import backgroundVertical from "../assets/gallery/darkMM1.png";
import Vimeo from "@u-wave/react-vimeo";

export const HomeSection = () => {
  return (
    <>
      <div href="home" style={{ color: "transparent", height: 0 }}>
        Home
      </div>
      <HomeContainer>
        <FixedContainer>
          <BackgroundContainer>
            <VideoContainer>
              <Video
                background={true}
                height={500}
                loop={true}
                responsive
                video="https://vimeo.com/1042400077?share=copy"
                width={200}
                id="home-video"
              />
            </VideoContainer>
            <Overlay />

            {/* Keeping this here in case I need it for another website or feature */}
            {/* TO DO: use Background as a "back up plan" in case video doesn't load. */}
            {/* <Background /> */}
          </BackgroundContainer>
          <RelativeContainer>
            <TitleContainer>
              <Title>Maya Mistress</Title>
            </TitleContainer>
            <SubTitle>
              Professional Dominatrix based in Amsterdam.
              <br />
              Power Dynamics Specialist. <br />
              For selected submissives.
            </SubTitle>
          </RelativeContainer>
        </FixedContainer>
      </HomeContainer>
    </>
  );
};

const FixedContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
`;

const HomeContainer = styled.div`
  height: 92vh;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -2;
`;

const Overlay = styled.div`
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100vh;
  z-index: 4;
`;

// Keeping code here for future use

// const Background = styled.div`
//   background-image: url(${background});
//   background-position: center;
//   height: 130vh;
//   width: 130vw;
//   margin-left: -18vw;
//   margin-top: -10vh;

//   z-index: -2;
//   opacity: 27%;
//   background-size: cover;

//   @media screen and (max-width: 468px) {
//     background-image: url(${backgroundVertical});
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: top;
//     height: 90vh;
//     margin-top: 5vh;
//   }
// `;

const RelativeContainer = styled.div`
  animation: fadeIn 2s ease-in-out;
  margin-top: -200px;
  text-align: center;
  width: 100%;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 468px) {
    margin: 0 auto;
  }

  @media screen and (max-height: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: auto;
  }
`;

const TitleContainer = styled.div``;

const Title = styled.h1`
  color: white;
  font-size: 108px;
  font-weight: 200;
  letter-spacing: 4px;
  margin-bottom: 14px;
  margin-top: 0px;
  font-family: "Scriptina";

  @media screen and (max-width: 468px) {
    font-size: 48px;
  }

  // for mobile landscape
  @media screen and (max-height: 468px) {
    font-size: 48px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const SubTitle = styled.h2`
  font-size: 20px;
  color: white;
  font-weight: 300;
  animation: fadeIn 1s ease-in-out;

  @media screen and (max-width: 468px) {
    font-size: 18px;
  }
`;

const VideoContainer = styled.div`
  bottom: 0;
  left: 0;
  min-height: 100%;
  min-width: 60%;
  object-fit: cover;
  object-position: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;

  @media screen and (max-height: 600px) {
    min-height: 40%;
    height: 70vh;
  }
`;

const Video = styled((props) => <Vimeo {...props} />)`
  height: 56.25vw; // for a 16:9 aspect ratio, 9/16*100 = 56.25
  left: 50%;
  min-height: 100vh;
  min-width: 177.77vh; // for a 16:9 aspect ratio, 16/9*100 = 177.77
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
`;
