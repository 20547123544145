import { useState, useEffect } from "react";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";
import { GrClose, GrMenu } from "react-icons/gr";
import LogoImage from "../assets/logos/LogoMayaMedium.png";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isUpper, setIsUpper] = useState(true);

  function handleMenuOpen() {
    setMenuOpen(!menuOpen);
  }

  const handleScroll = () => setOffset(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (offset > 100) {
      setIsUpper(false);
    } else {
      setIsUpper(true);
    }
  }, [offset]);

  return (
    <Nav transparentBackground={isUpper}>
      <Link to="/">
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Link>

      <BurgerMenuWrapper onClick={handleMenuOpen}>
        <Menu
          noOverlay
          disableOverlayClick={true}
          width="60vw"
          right
          customBurgerIcon={!menuOpen && <GrMenu size={20} />}
          customCrossIcon={<GrClose size={10} />}
          onOpen={handleMenuOpen}
          onClose={handleMenuOpen}
          isOpen={menuOpen}
        >
          <Link to="/sessions">Sessions</Link>
          <ItemsDivider>|</ItemsDivider>
          <Link to="/mentoring">Mentoring</Link>
          <ItemsDivider>|</ItemsDivider>
          <Link to="/remote">Remote</Link>
          <ItemsDivider> |</ItemsDivider>
          <a href="https://throne.com/mayamistress">WishList</a>
          <ItemsDivider> </ItemsDivider>
        </Menu>
      </BurgerMenuWrapper>
    </Nav>
  );
};

export const LogoContainer = styled.div`
  margin-left: 15px;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  position: fixed;
  z-index: 99;
`;

const Nav = styled.div`
  position: fixed;
  height: 60px;
  width: 100vw;
  right: 0;
  z-index: 2;
  overflow: visible;
  top: 0;

  background-color: ${(props) =>
    props.transparentBackground ? "transparent" : "black"};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }

  @media screen and (max-width: 468px) {
    height: 80px;
  }
`;

const BurgerMenuWrapper = styled.div`
  position: fixed;
  right: 12.5px;
  z-index: 4;
`;

const ItemsDivider = styled.span`
  margin: 0 18px;

  @media screen and (max-width: 500px) {
    margin: 0 6px;
  }
`;

export const Logo = styled.div`
  background-image: url(${LogoImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;
